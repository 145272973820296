import styled from 'styled-components'
import { DEVICE } from '../../util/constants'
import { Link } from 'gatsby'

export const SearchResultItem = styled(Link)`
  grid-column: 2 / 3;
  width: 100%;
  margin-bottom: 40px;
  text-decoration-line: none;

  :hover {
    text-decoration-line: underline;
  }

  @media ${DEVICE.tabletL} {
    padding: 0 10%;
  }
`

export const SearchResultTitle = styled.h2`
  font-style: normal;
  font-weight: normal;
  display: flex;
  font-size: 2.2rem;
  margin-bottom: 10px;
  line-height: 1.375;
  color: var(--graphic-accent-purple-2);

  @media ${DEVICE.tabletL} {
    font-size: 2.4rem;
  }
`

export const SearchResultContent = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.375;
  color: var(--content-background-dark-blue);

  @media ${DEVICE.tabletL} {
    font-size: 1.8rem;
  }
`
